import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Endpoint for signing out the user
    getUserData: build.query({
      query: () => "/users",
    }),

   

    updateUserData: build.mutation({
      query: ({ password, alias }) => ({
        url: "/users",
        method: "PUT",
        body: { password, alias },
      }),
      async onQueryStarted({ password, alias }, { dispatch, queryFulfilled }) {
        const postResult = dispatch(
          apiSlice.util.updateQueryData("getUserData", undefined, (user) => {
            user.alias = alias;
          })
        );
        queryFulfilled.catch(postResult.undo);
      },
    }),

    updateUserSettings: build.mutation({
      query: ({ themeColor }) => ({
        url: "/users/settings",
        method: "POST",
        body: { themeColor },
      }),
      async onQueryStarted({ themeColor }, { dispatch, queryFulfilled }) {
        const postResult = dispatch(
          apiSlice.util.updateQueryData("getUserData", undefined, (user) => {
            user.themeColor = themeColor;
          })
        );
        queryFulfilled.catch(postResult.undo);
      },
    }),

    deleteUserData: build.mutation({
      query: () => ({
        url: "/users",
        method: "DELETE",
      }),
    }),

   
     getUserSettings: build.query({
      query: () => "/users/settings",
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUpdateUserSettingsMutation,
  useDeleteUserDataMutation,
  useGetUserSettingsQuery, 

} = authApiSlice;
