import { baseFetch } from "./authService";

const AI_DATA_MANAGEMENT_API_URL =
  process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL;
const AI_API_URL = process.env.REACT_APP_AI_API_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

let accessToken = localStorage.getItem("accessToken");

//!SECTION Trainings

export const getTrainingsData = async () => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    "/cultzyme-ai-data-management-api/v1/trainings/does",
    {
      method: "GET",
    }
  );
};

//NOTE Creo que no esta en uso
export const getTrainingData = async (trainingDoeId) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/${trainingDoeId}`,
    {
      method: "GET",
    }
  );
};

export const deleteTraining = async (bodySelectedTrainings) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    "/cultzyme-ai-data-management-api/v1/trainings/does",
    {
      method: "DELETE",
      body: JSON.stringify(bodySelectedTrainings),
    }
  );
};

export const getTrainingDoePdf = async (trainingDoeId, pdfId, pdfName) => {
  const response = await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/pdfs/download/${trainingDoeId}/${pdfId}`,
    {
      method: "GET",
      returnResponse: true,
    }
  );

  let fileName = `${pdfName}_training_Cultzyme.pdf`;

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getTrainingImpactFactors = async (trainingDoeId) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/impact-factors/${trainingDoeId}`,
    {
      method: "GET",
    }
  );
};

export const rentryTrainingImpactFactors = async (payload) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    }
  );
};

export const getDesignDetails = async (trainingDoeId, experimentalDesignId) => {
  return baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/${trainingDoeId}/${experimentalDesignId}`,
    {
      method: "GET",
    }
  );
};

export const getPdfExperimentalDesign = async (
  trainingDoeId,
  experimentalDesignId,
  experimentalDesignName
) => {
  const response = await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/download-pdf/${trainingDoeId}/${experimentalDesignId}`,
    {
      method: "GET",
      returnResponse: true,
    }
  );

  // const contentDisposition = response.headers.get("Content-Disposition");
  let fileName = `${experimentalDesignName}_Cultzyme_experimental_design.pdf`;
  // if (contentDisposition) {
  //   const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
  //   if (fileNameMatch && fileNameMatch[1]) {
  //     fileName = fileNameMatch[1];
  //   }
  // }
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

//NOTE - Se usa en la tabla?
export const startExperimentalDesign = async (
  trainingDoeId,
  experimentalDesignId
) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/start`,
    {
      method: "PUT",
      body: JSON.stringify({
        trainingDoeId,
        experimentalDesignId,
      }),
    }
  );
};

export const getExperimentalMesurements = async (
  trainingDoeId,
  experimentalDesignId
) => {
  return baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    `/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/${trainingDoeId}/${experimentalDesignId}`,
    {
      method: "GET",
    }
  );
};

export const getMoleculeTypes = async () => {
  return baseFetch(
    BACKEND_URL,
    `/cultzyme-api/v1/administrations/molecule-types`,
    {
      method: "GET",
    }
  );
};

export const getUnits = async () => {
  return baseFetch(BACKEND_URL, `/cultzyme-api/v1/administrations/units`, {
    method: "GET",
  });
};

export const addMeasurement = async (body) => {
  return await baseFetch(
    AI_DATA_MANAGEMENT_API_URL,
    "/cultzyme-ai-data-management-api/v1/trainings/does/measurements",
    {
      method: "POST",
      body: body,
    }
  );
};

// export const finishExperimentalDesign = async (trainingDoeId, experimentalDesignId, formData) => {
//   console.log("SERVICE FINISH DESIGN");

//   const response = await fetch(
//     `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/finish?trainingDoeId=${trainingDoeId}&experimentalDesignId=${experimentalDesignId}`,
//     {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//       body: formData,
//     }
//   );

//   if (!response.ok) {
//     throw new Error(`HTTP error! status: ${response.status}`);
//   }

//   const data = await response.json();
//   return data;
// }

//!SECTION CHATAGENTS

export const getAgentChat = async (chatId) => {
  const data = await baseFetch(
    AI_API_URL,
    `/cultzyme-ai-api/v1/chats/${chatId}`,
    {
      method: "GET",
    }
  );
  return { data, error: null };
};

export const getAgentChats = async () => {
  const data = await baseFetch(AI_API_URL, `/cultzyme-ai-api/v1/chats`, {
    method: "GET",
  });

  return { data, error: null };
};

export const getAgentSuggestions = async (aiResponse) => {
  return await baseFetch(
    AI_API_URL,
    `/cultzyme-ai-api/v1/assistants/suggestions`,
    {
      method: "POST",
      body: JSON.stringify({
        message: aiResponse,
      }),
    }
  );
};

export const sendAgentMessage = async (
  inputMessage,
  selectedChat,
  temperature,
  usePublicData
) => {
  return await baseFetch(AI_API_URL, `/cultzyme-ai-api/v1/assistants`, {
    method: "POST",
    body: JSON.stringify({
      text: inputMessage,
      chatId: selectedChat.chatId,
      temperature: temperature,
      withPublicData: usePublicData,
    }),
    returnResponse: true,
  });
};

export const deleteAgentChat = async (chatId) => {
  const data = await baseFetch(
    AI_API_URL,
    `/cultzyme-ai-api/v1/chats/${chatId}`,
    {
      method: "DELETE",
    }
  );
  return { data, error: null };
};


//!SECTION INSILICO

export const getInSIlicoPromptData = async () => {
  return await baseFetch(BACKEND_URL, '/cultzyme-api/v1/administrations/AI_SEARCH_ENG_PROMPT/base-prompt', {
    method: 'GET', 
  });
}

export const getInSIlicoPlaceholdersData = async () => {
  return await baseFetch(BACKEND_URL, '/cultzyme-api/v1/administrations/ai-search-engines/placeholders', {
    method: 'GET', 
  });
} 
export const getBioReactorModels = async (volume) => {
  return await baseFetch(BACKEND_URL, `/cultzyme-api/v1/administrations/bioreactor-models/${volume}`, {
    method: 'GET', 
  });
} 

export const getMicroorganisms = async (endpoint) => {
  return await baseFetch(BACKEND_URL, endpoint, {
    method: 'GET', 
  });
}

export const createTraining = async (formData) => {
  return await baseFetch(AI_DATA_MANAGEMENT_API_URL, '/cultzyme-ai-data-management-api/v1/trainings/does', {
    method: 'POST',
    body: formData,
    multipartFormData: true
  });
}

export const getInSIlicoMoleculeTypes = async (selectedType, term) => {
  return await baseFetch(BACKEND_URL, `/cultzyme-api/v1/administrations/molecule-types`, {
    method: 'GET',
  });
}

// NOTE No se esta usando aun
export const getInSIlicoMolecule = async (selectedType, term) => {
  return await baseFetch(BACKEND_URL, `/cultzyme-api/v1/administrations/molecules/${selectedType.value.toLowerCase()}/${term}`, {
    method: 'GET',
  });
}

//!SECTION BIONS 

export const getPusherAuth = async (socketId, channelName) => {
  return baseFetch(BACKEND_URL, '/cultzyme-api/v1/pusher/private-auth', {
    method: 'POST',
    body: JSON.stringify({
      socketId: socketId,
      channel: channelName,
    })
  });
}
