import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../../components/UI/Shadcn/Card";
import { Button } from "../../components/UI/Shadcn/Button";
import { BrainCircuit, Maximize2, ArrowRight } from "lucide-react";
import { motion } from "framer-motion";

const NewFiles = () => {
  const navigate = useNavigate();

  const cardData = [
    {
      title: "AI Agent",
      description: "Get instant AI-powered responses to your queries",
      content:
        "Leverage our advanced AI to answer your questions about processes, optimizations, and more. Get accurate and instant responses to streamline your decision-making.",
      icon: BrainCircuit,
      buttonText: "Start Asking Questions",
      onClick: () => navigate("/chatbot"),
    },
    {
      title: "Trainings",
      description: "Optimize workflows for peak efficiency",
      content:
        "Use our AI-driven tools to analyze and optimize your production workflows. Identify bottlenecks, suggest improvements, and maximize your overall efficiency.",
      icon: Maximize2,
      buttonText: "Check your trainings",
      // onClick: () => navigate("/cultzyme-ai/maximize"),
      onClick: () => navigate("/trainings"),
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-background p-6 md:p-10">
      <motion.header
        className="text-center mb-12"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl text-foreground">
        Your AI Training Hub
        </h1>
        <p className="mt-4 text-lg text-muted-foreground max-w-2xl mx-auto">
        Access and manage AI trainings while exploring insights through interactive chats.
        </p>
      </motion.header>

      {/* TODO: Implementar prompt */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto w-full">
        {cardData.map((card, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <Card className="bg-card text-card-foreground border-2 border-border shadow-lg transition-all duration-300 hover:shadow-xl hover:border-primary h-full flex flex-col">
              <CardHeader className="space-y-1">
                <CardTitle className="text-2xl font-bold flex items-center">
                  <card.icon className="mr-2 h-6 w-6 text-primary" />
                  {card.title}
                </CardTitle>
                <CardDescription>{card.description}</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-sm leading-6">{card.content}</p>
              </CardContent>
              <CardFooter>
                <Button
                  variant="default"
                  className="w-full bg-primary hover:bg-primary/90 text-primary-foreground transition-colors duration-200 group"
                  onClick={card.onClick}
                >
                  {card.buttonText}
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform duration-200 group-hover:translate-x-1" />
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default NewFiles;
