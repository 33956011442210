import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { debounce } from "lodash";
import { ChevronDown, Check, Loader2 } from "lucide-react";
import { getMicroorganisms } from "../../../service";

const MicroOrganismCombobox = ({
  value,
  onChange,
  accessToken,
  selectedMicroOrganism,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [options, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const comboboxRef = useRef(null);
  const inputRef = useRef(null);

  const fetchMicroOrganisms = useCallback(
    async (type, term) => {
      if (!accessToken || !type || !term) {
        console.warn("Missing accessToken, type, or search term");
        setOptions([]);
        return;
      }

      setIsSearching(true);
      setError(null);

      try {
        let endpoint;
        switch (type.toLowerCase()) {
          case "yeasts":
            endpoint = `/cultzyme-api/v1/administrations/micro-organisms/yeasts/${term}`;
            break;
          case "bacteria":
            endpoint = `/cultzyme-api/v1/administrations/micro-organisms/bacterias/${term}`;
            break;
          case "animal_cells":
            endpoint = `/cultzyme-api/v1/administrations/micro-organisms/animal-cells/${term}`;
            break;
          default:
            throw new Error("Invalid microorganism type");
        }


        const data = await getMicroorganisms(endpoint);
      

        const fetchedOptions = Array.isArray(data) ? data : [];
        setOptions(fetchedOptions);

        if (fetchedOptions.length === 0) {
          console.warn("No options returned from the API");
        }
      } catch (err) {
        console.error("Error fetching micro-organisms:", err);
        setError(err.message || "Failed to fetch micro-organisms.");
        setOptions([]);
      } finally {
        setIsSearching(false);
      }
    },
    [accessToken]
  );

  const debouncedFetchMicroOrganisms = useMemo(
    () => debounce(fetchMicroOrganisms, 300),
    [fetchMicroOrganisms]
  );

  useEffect(() => {
    if (selectedMicroOrganism && inputValue) {
      debouncedFetchMicroOrganisms(selectedMicroOrganism, inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, selectedMicroOrganism, debouncedFetchMicroOrganisms]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleComboboxToggle = () => {
    if (selectedMicroOrganism) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        // Focus the input field when opening the combobox
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
  };

  return (
    <div className="relative w-full max-w-xs" ref={comboboxRef}>
      <button
        onClick={handleComboboxToggle}
        className={`flex items-center justify-between w-full px-4 py-2 text-left bg-background border border-input rounded-md shadow-sm hover:bg-accent hover:text-accent-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:border-input transition-colors duration-200 ${
          !selectedMicroOrganism && "opacity-50 cursor-not-allowed"
        }`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={!selectedMicroOrganism}
      >
        <span className={`block truncate text-foreground`}>
          {value || `Select ${selectedMicroOrganism || "Microorganism"}`}
        </span>
        <ChevronDown className="w-5 h-5 text-muted-foreground" />
      </button>

      {isOpen && selectedMicroOrganism && (
        <div className="absolute z-10 w-full mt-1 bg-popover rounded-md shadow-lg border border-border transition-all duration-200 ease-in-out opacity-100 scale-100 origin-top">
          <div className="p-2">
            <input
              ref={inputRef}
              type="text"
              className="w-full px-3 py-2 text-sm bg-muted text-muted-foreground border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-ring focus:border-input"
              placeholder="Search options..."
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <ul className="max-h-60 overflow-auto py-1 text-base" role="listbox">
            {isSearching ? (
              <li className="relative px-3 py-2 text-muted-foreground cursor-default select-none flex items-center">
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Searching...
              </li>
            ) : error ? (
              <li className="relative px-3 py-2 text-destructive cursor-default select-none">
                {error}
              </li>
            ) : options.length === 0 ? (
              <li className="relative px-3 py-2 text-muted-foreground cursor-default select-none">
                No results found
              </li>
            ) : (
              options.map((option) => (
                <li
                  key={option}
                  className={`relative px-3 py-2 cursor-pointer select-none transition-colors duration-200
                    ${
                      value === option
                        ? "bg-primary text-primary-foreground"
                        : "text-popover-foreground hover:bg-accent hover:text-accent-foreground"
                    }`}
                  role="option"
                  aria-selected={value === option}
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                    setInputValue("");
                  }}
                >
                  <span className="block truncate">{option}</span>
                  {value === option && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-primary-foreground">
                      <Check className="w-5 h-5" />
                    </span>
                  )}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MicroOrganismCombobox;
