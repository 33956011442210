import React from "react";
import { Button } from "../UI/Shadcn/Button";
import { ScrollArea } from "../UI/Shadcn/ScrollArea";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UI/Shadcn/DropdownMenu";
import {
  ChevronLeft,
  Download,
  Ellipsis,
  Loader2,
  MessageSquare,
  Trash2,
} from "lucide-react";
import { useTheme } from "../ThemeProvider";

export default function ChatbotSidebar({
  chats,
  selectedChat,
  isLoading,
  isSidebarOpen,
  toggleSidebar,
  selectChat,
  deleteChat,
  exportChat,
  isChartFullScreen
}) {
  const { theme } = useTheme();
  const isOpen = isSidebarOpen && !isChartFullScreen;

  
  
  

  return (
    <div
      className={` bg-background  border-r border-border flex flex-col h-full transition-all duration-300 ease-in-out overflow-hidden ${
        isOpen ? "translate-x-0 w-80" : "-translate-x-full w-0"
      }`}
    >
      <div className="p-4 flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-foreground">Chats</h2>
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleSidebar}
            className="md:hidden text-muted-foreground hover:text-foreground"
          >
            <ChevronLeft className="h-5 w-5" />
          </Button>
        </div>
        {chats.length !== 0 && (
          <Button
            onClick={exportChat}
            className="w-full bg-secondary text-secondary-foreground hover:bg-secondary/90"
            disabled={chats.length === 0 || !selectedChat}
          >
            <Download className="h-4 w-4 mr-2" />
            <span>Export Chat</span>
          </Button>
        )}
      </div>
      <ScrollArea className="flex-grow px-2 bg-background">
        {isLoading ? (
          <div className="text-center text-muted-foreground py-4">
            <Loader2 className="h-6 w-6 animate-spin mx-auto" />
            <p className="mt-2">Loading chats...</p>
          </div>
        ) : chats.length === 0 ? (
          // <div className="text-center text-muted-foreground py-4">
          //   <p className="px-4">
          //     You don't have any chats yet. To create a new chat, go to your{" "}
          //     <Link
          //       to="/trainings"
          //       className="text-blue-500 hover:text-blue-700 underline"
          //     >
          //       trainings
          //     </Link>
          //     .
          //   </p>
          // </div>
          <div className="text-center text-muted-foreground py-4">
            <p>No chats available.</p>
          </div>
        ) : (
          chats.map((chat) => (
            <div
              key={chat.chatId}
              className="flex items-center justify-between mb-2 group"
            >
              <Button
                variant={
                  chat.chatId === selectedChat?.chatId ? "secondary" : "ghost"
                }
                className={`w-full justify-start text-left truncate ${
                  chat.chatId === selectedChat?.chatId
                    ? "bg-secondary text-secondary-foreground"
                    : "text-muted-foreground hover:text-foreground hover:bg-accent"
                }`}
                onClick={() => selectChat(chat)}
              >
                <MessageSquare className="mr-2 h-4 w-4 flex-shrink-0" />
                <span className="truncate">
                  {chat.preview || "No preview available"}
                </span>
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="flex-shrink-0 opacity-100 group-hover:opacity-50 transition-opacity text-muted-foreground "
                  >
                    {theme.themeColor === "dark" ? (
                      <Ellipsis color="#fefbfb" />
                    ) : (
                      <Ellipsis />
                    )}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={(e) => deleteChat(chat.chatId, e)}
                    className="text-destructive focus:text-destructive focus:bg-destructive/10"
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))
        )}
      </ScrollArea>
    </div>
  );
}
