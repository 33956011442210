import React, { useState, useEffect, useCallback } from "react";
import { Button } from "../../../../components/UI/Shadcn/Button";
import { Label } from "../../../../components/UI/Shadcn/Label";
import { Input } from "../../../../components/UI/Shadcn/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/UI/Shadcn/Select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/UI/Shadcn/Table";
import {
  Plus,
  CalendarIcon,
  
  AlertTriangle,
  Beaker,
  Scale,
  ChevronDown,
  Check,
  Search,
  Loader2,
  ChevronLeft,
  ChevronRight,
  Lock,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/UI/Shadcn/Popover";
import {
  format,
  parseISO,
  set,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  
} from "date-fns";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../../components/UI/Shadcn/Alert";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/UI/Shadcn/Card";
import { ScrollArea } from "../../../../components/UI/Shadcn/ScrollArea";
import { toast } from "../../../../hooks/use-toast";
import { addMeasurement, getExperimentalMesurements, getMoleculeTypes, getUnits } from "../../../../service";
import { baseFetch } from "../../../../service/authService";

const CustomMoleculeCombobox = ({
  value,
  onChange,
  accessToken,
  selectedMoleculeType,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchMolecules = useCallback(async () => {
    if (!accessToken || !selectedMoleculeType || !searchTerm) {
      setOptions([]);
      return;
    }

    setLoading(true);
    try {
      let endpoint;
      switch (selectedMoleculeType.toUpperCase()) {
        case "PATHWAYS":
          endpoint = `/cultzyme-api/v1/administrations/molecules/pathways/${searchTerm}`;
          break;
        case "NUCLEIC_ACIDS":
          endpoint = `/cultzyme-api/v1/administrations/molecules/nucleic-acids/${searchTerm}`;
          break;
        case "METABOLITES":
          endpoint = `/cultzyme-api/v1/administrations/molecules/metabolites/${searchTerm}`;
          break;
        case "LIPIDS":
          endpoint = `/cultzyme-api/v1/administrations/molecules/lipids/${searchTerm}`;
          break;
        case "GLYCANS":
          endpoint = `/cultzyme-api/v1/administrations/molecules/glycans/${searchTerm}`;
          break;
        case "GENES":
          endpoint = `/cultzyme-api/v1/administrations/molecules/genes/${searchTerm}`;
          break;
        case "ENZYME":
          endpoint = `/cultzyme-api/v1/administrations/molecules/enzymes/${searchTerm}`;
          break;
        case "CHEMICAL_REACTION":
          endpoint = `/cultzyme-api/v1/administrations/molecules/chemical-reactions/${searchTerm}`;
          break;
        default:
          throw new Error("Invalid molecule type");
      }

      const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

      const getMoleculeTypesSearch = async () => {
        return baseFetch(BACKEND_URL, endpoint, {
          method: "GET",
        });
      };

      const data = await getMoleculeTypesSearch();
    

      setOptions(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching molecules:", error);
      toast({
        title: "Error",
        description: "Failed to fetch molecules. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [accessToken, selectedMoleculeType, searchTerm]);

  useEffect(() => {
    if (isOpen && selectedMoleculeType) {
      fetchMolecules();
    }
  }, [isOpen, selectedMoleculeType, fetchMolecules]);

  return (
    <Popover open={isOpen && !disabled} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={isOpen}
          className="w-full justify-between"
          disabled={!selectedMoleculeType || disabled}
        >
          <span className="truncate">{value || "Select molecule..."}</span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50 flex-shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <div className="flex items-center border-b px-3 py-2">
          <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
          <Input
            placeholder="Search molecules..."
            className="border-0 focus:ring-0"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <ScrollArea className="h-[300px]">
          {loading ? (
            <div className="flex items-center justify-center py-6">
              <Beaker className="mr-2 h-4 w-4 animate-spin" />
              Loading...
            </div>
          ) : (
            options.map((option) => (
              <div
                key={option}
                className="flex cursor-pointer items-center justify-between px-3 py-2 hover:bg-accent"
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                <span className="truncate flex-grow mr-2">{option}</span>
                {value === option && (
                  <Check className="h-4 w-4 flex-shrink-0" />
                )}
              </div>
            ))
          )}
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};

const CustomCalendar = ({ selected, onSelect, disabled }) => {
  const [currentMonth, setCurrentMonth] = useState(selected || new Date());

  const handlePreviousMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const days = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <Button
          variant="outline"
          size="icon"
          onClick={handlePreviousMonth}
          disabled={disabled}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="font-semibold">{format(currentMonth, "MMMM yyyy")}</div>
        <Button
          variant="outline"
          size="icon"
          onClick={handleNextMonth}
          disabled={disabled}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
          <div key={day} className="text-center font-medium text-sm">
            {day}
          </div>
        ))}
        {days.map((day) => (
          <Button
            key={day.toString()}
            variant={isSameDay(day, selected) ? "default" : "outline"}
            className={`p-2 ${
              !isSameMonth(day, currentMonth) ? "text-muted-foreground" : ""
            }`}
            onClick={() => onSelect(day)}
            disabled={disabled}
          >
            {format(day, "d")}
          </Button>
        ))}
      </div>
    </div>
  );
};

const Measurements = ({
  accessToken,
  trainingDoeId,
  experimentalDesignId,
  onStartDesign,
  onClose,
  isViewOnly,
}) => {
  const [measurements, setMeasurements] = useState([]);
  const [newMeasurement, setNewMeasurement] = useState({
    measureDate: new Date(),
    molecule: "",
    moleculeType: "",
    quantity: "",
    unit: "",
  });
  const [moleculeTypes, setMoleculeTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  
  const isButtonDisabled = isLoading || !isStarted || isViewOnly  || !newMeasurement.quantity || !newMeasurement.unit;

  const fetchMeasurements = useCallback(async () => {  
  
    try {
      const data = await getExperimentalMesurements(trainingDoeId, experimentalDesignId);
      setMeasurements(data.measurements || []);
      setIsStarted(data.startDate != null);
    } catch (error) {
      console.error("Failed to fetch experimental design:", error);
      setError("Failed to fetch experimental design. Please try again.");
    }
  }, [accessToken, trainingDoeId, experimentalDesignId]);

  useEffect(() => {
    const fetchMoleculeTypes = async () => {
      
      try {
       const data = await getMoleculeTypes();
        setMoleculeTypes(data);
      } catch (error) {
        console.error("Error fetching molecule types:", error);
        setError("Failed to fetch molecule types. Please try again.");
      }
    };

    const fetchUnits = async () => {    
      try {
       const data = await getUnits();
        setUnits(data);
      } catch (error) {
        console.error("Error fetching units:", error);
        setError("Failed to fetch units. Please try again.");
      }
    };

    fetchMeasurements();
    fetchMoleculeTypes();
    fetchUnits();
  }, [accessToken, fetchMeasurements]);

  const handleAddMeasurement = async () => {
    if (isViewOnly) {
      toast({
        title: "Error",
        description: "Cannot add measurements in view-only mode.",
        variant: "destructive",
      });
      return;
    }

    if (!isStarted) {
      toast({
        title: "Error",
        description:
          "You need to start the experimental design before adding measurements.",
        variant: "destructive",
      });
      return;
    }

    if (!newMeasurement.quantity || !newMeasurement.unit) {
      toast({
        title: "Error",
        description: "Please enter both quantity and unit.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      const body = JSON.stringify({
        trainingDoeId,
        experimentalDesignId,
        measures: [
          {
            measureDate: newMeasurement.measureDate.toISOString(),
            molecule: newMeasurement.molecule,
            moleculeType: newMeasurement.moleculeType,
            quantity: parseFloat(newMeasurement.quantity),
            unit: newMeasurement.unit,
          },
        ],
      });

      await  addMeasurement(body);

      await fetchMeasurements();

      setNewMeasurement({
        measureDate: new Date(),
        molecule: "",
        moleculeType: "",
        quantity: "",
        unit: "",
      });

      toast({
        title: "Measurement added successfully",
        description: "The new measurement has been recorded.",
      });
    } catch (error) {
      console.error("Failed to add measurement:", error);
      toast({
        title: "Error adding measurement",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setNewMeasurement((prev) => ({
      ...prev,
      measureDate: set(prev.measureDate, {
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
      }),
    }));
  };

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(":");
    setNewMeasurement((prev) => ({
      ...prev,
      measureDate: set(prev.measureDate, {
        hours: parseInt(hours, 10),
        minutes: parseInt(minutes, 10),
      }),
    }));
  };

  const formatDate = (dateString) => {
    try {
      return format(parseISO(dateString), "dd/MM/yyyy HH:mm");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          Measurements
          {isViewOnly && (
            <span className="ml-2 text-yellow-500 text-sm">
              <Lock className="inline-block h-4 w-4 mr-1" />
              View Only
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold mb-2">
              Recorded Measurements
            </h3>
            {measurements.length > 0 ? (
              <ScrollArea className="h-[200px]">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Date</TableHead>
                      <TableHead>Molecule</TableHead>
                      <TableHead>Type</TableHead>
                      <TableHead>Quantity</TableHead>
                      <TableHead>Unit</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {measurements.map((measurement, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {formatDate(measurement.measureDate)}
                        </TableCell>
                        <TableCell>{measurement.moleculeDescription}</TableCell>
                        <TableCell>
                          {measurement.moleculeTypeDescription}
                        </TableCell>
                        <TableCell>{measurement.quantity}</TableCell>
                        <TableCell>{measurement.unitDescription}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollArea>
            ) : (
              <p className="text-muted-foreground">
                No measurements recorded yet.
              </p>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Add New Measurement</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="measureDate">Date and Time</Label>
                <div className="flex space-x-2">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className={`w-full justify-start text-left font-normal ${
                          !newMeasurement.measureDate && "text-muted-foreground"
                        }`}
                        disabled={isViewOnly}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {newMeasurement.measureDate ? (
                          format(newMeasurement.measureDate, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <CustomCalendar
                        selected={newMeasurement.measureDate}
                        onSelect={handleDateChange}
                        disabled={isViewOnly}
                      />
                    </PopoverContent>
                  </Popover>
                  <div className="relative flex-1">
                    {/* <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" /> */}
                    <Input
                      type="time"
                      className="pl-5"
                      value={format(newMeasurement.measureDate, "HH:mm")}
                      onChange={handleTimeChange}
                      disabled={isViewOnly}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="moleculeType">Molecule Type</Label>
                <Select
                  value={newMeasurement.moleculeType}
                  onValueChange={(value) =>
                    setNewMeasurement((prev) => ({
                      ...prev,
                      moleculeType: value,
                      molecule: "",
                    }))
                  }
                  disabled={isViewOnly}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a molecule type" />
                  </SelectTrigger>
                  <SelectContent>
                    {moleculeTypes.map((type) => (
                      <SelectItem key={type.value} value={type.value}>
                        {type.description}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="molecule">Molecule</Label>
                <CustomMoleculeCombobox
                  value={newMeasurement.molecule}
                  onChange={(value) =>
                    setNewMeasurement((prev) => ({
                      ...prev,
                      molecule: value,
                    }))
                  }
                  accessToken={accessToken}
                  selectedMoleculeType={newMeasurement.moleculeType}
                  disabled={isViewOnly}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="quantity">Quantity and Unit</Label>
                <div className="flex space-x-2">
                  <div className="relative flex-1">
                    <Scale className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    <Input
                      id="quantity"
                      type="number"
                      className="pl-10"
                      value={newMeasurement.quantity}
                      onChange={(e) =>
                        setNewMeasurement((prev) => ({
                          ...prev,
                          quantity: e.target.value,
                        }))
                      }
                      min="0"
                      step="0.01"
                      placeholder="Quantity"
                      disabled={isViewOnly}
                    />
                  </div>
                  <Select
                    value={newMeasurement.unit}
                    onValueChange={(value) =>
                      setNewMeasurement((prev) => ({
                        ...prev,
                        unit: value,
                      }))
                    }
                    disabled={isViewOnly}
                  >
                    <SelectTrigger className="w-[50%]">
                      <SelectValue placeholder="Unit" />
                    </SelectTrigger>
                    <SelectContent>
                      {units.map((unit) => (
                        <SelectItem key={unit.unitId} value={unit.unitId}>
                          {unit.description}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <Button
              className="mt-6 w-full bg-primary hover:bg-primary/90 text-primary-foreground"
              onClick={handleAddMeasurement}
              disabled={isLoading || !isStarted || isViewOnly}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Adding Measurement...
                </>
              ) : !isStarted ? (
                "Start Experimental Design to Add Measurements"
              ) : (
                <>
                  <Plus className="mr-2 h-4 w-4" />
                  Add Measurement
                </>
              )}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Measurements;
