import React, { useRef, useEffect, useState, useCallback } from "react";
import Plotly from "plotly.js-dist";
import { ScrollArea } from "../UI/Shadcn/ScrollArea";
import { Avatar, AvatarFallback, AvatarImage } from "../UI/Shadcn/Avatar";
import { Button } from "../UI/Shadcn/Button";
import {
  Bot,
  Loader2,
  Menu,
  ArrowDown,
  Maximize,
  X,
  Sparkles,
} from "lucide-react";
import ChatContext from "../AI/ChatContext";

export default function ChatbotBody({
  selectedChat,
  messages,
  isTyping,
  isChatLoading,
  toggleSidebar,
  accessToken,
  onSetChartFullScreen,
  isChartFullScreen,
}) {
  const scrollAreaRef = useRef(null);
  const chartRefs = useRef({});
  const initializedCharts = useRef(new Set());
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  // const [chartLoadingStates, setChartLoadingStates] = useState({});

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const chartKey = entry.target.getAttribute("data-chart-key");
      if (chartKey && chartRefs.current[chartKey]?.current) {
        Plotly.Plots.resize(chartRefs.current[chartKey].current);
      }
    }
  });

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector(
        "[data-radix-scroll-area-viewport]"
      );
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      setIsInitialLoad(false);
    }
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isChatLoading && messages.length > 0) {
      setIsInitialLoad(false);
    }
  }, [isChatLoading, messages]);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    setShowScrollButton(!isNearBottom);
  };

  const extractChartData = (content) => {
    try {
      const scriptMatch = content.match(/<script>([\s\S]*?)<\/script>/);
      if (!scriptMatch) return null;

      const scriptContent = scriptMatch[1];

      const result = new Function(`
            ${scriptContent.split("Plotly.newPlot")[0]}
            return { data, layout };
        `)();

      result.layout = {
        ...result.layout,
        height: 600,
        width: 800,
      };

      if (window.innerWidth <= 700) {
        result.layout = {
          ...result.layout,
          height: 300,
          width: 375,
          title: {
            ...result.layout.title,
            font: {
              color: "#ffffff",
              size: 8,
            },
          },
          xaxis: {
            ...result.layout.xaxis,
            title: {
              ...result.layout.scene?.xaxis?.title,
              font: { color: "#ffffff", size: 6 },
              standoff: 35,
            },
            tickfont: { color: "#ffffff", size: 6 },
            automargin: true,
          },
          yaxis: {
            ...result.layout.yaxis,
            title: {
              ...result.layout.scene?.yaxis?.title,
              font: { color: "#ffffff", size: 6 },
              standoff: 35,
            },
            tickfont: { color: "#ffffff", size: 6 },
            automargin: true,
          },
          zaxis: {
            ...result.layout.zaxis,
            title: {
              ...result.layout.scene?.zaxis?.title,
              font: { color: "#ffffff", size: 8 },
              standoff: 35,
            },
            tickfont: { color: "#ffffff", size: 6 },
            automargin: true,
          },
          bgcolor: "rgb(3, 7, 36)",
        };
      }

      const is3DChart = result.data.some(
        (trace) => trace.type === "scatter3d" || trace.type === "surface"
      );

      if (is3DChart) {
        result.layout = {
          ...result.layout,
          title: {
            text: result.layout.title?.text,
            font: { color: "#ffffff", size: 18 }, // Slightly smaller font
            y: 0.95,
            x: 0.5,
            xanchor: "center",
            yanchor: "top",
            pad: { t: 30 },
          },
          scene: {
            ...result.layout.scene,
            camera: {
              eye: { x: 1.87, y: 0.88, z: 0.64 },
              up: { x: 0, y: 0, z: 1 },
              center: { x: 0, y: 0, z: -0.1 },
            },
            aspectmode: "manual",
            aspectratio: { x: 1.2, y: 1.2, z: 1 },
            domain: {
              // This controls the chart size within container
              x: [0.05, 0.85], // Leave space for labels
              y: [0, 0.95], // Leave space for title
            },
            xaxis: {
              ...result.layout.scene?.xaxis,
              title: {
                ...result.layout.scene?.xaxis?.title,
                font: { color: "#ffffff", size: 12 },
                standoff: 35,
              },
              tickfont: { color: "#ffffff", size: 10 },
              automargin: true,
            },
            yaxis: {
              ...result.layout.scene?.yaxis,
              title: {
                ...result.layout.scene?.yaxis?.title,
                font: { color: "#ffffff", size: 12 },
                standoff: 35,
              },
              tickfont: { color: "#ffffff", size: 10 },
              automargin: true,
            },
            zaxis: {
              ...result.layout.scene?.zaxis,
              title: {
                ...result.layout.scene?.zaxis?.title,
                font: { color: "#ffffff", size: 12 },
                standoff: 35,
              },
              tickfont: { color: "#ffffff", size: 10 },
              automargin: true,
            },
            bgcolor: "rgb(3, 7, 36)",
          },
          margin: {
            l: 30,
            r: 100, // More space for colorbar
            t: 100, // More space for title
            b: 30,
            pad: 20,
          },
          coloraxis: {
            colorbar: {
              title: {
                font: { color: "#ffffff", size: 12 },
                side: "right",
              },
              tickfont: { color: "#ffffff", size: 10 },
              len: 0.6, // Shorter colorbar
              y: 0.5,
              yanchor: "middle",
              thickness: 15, // Thinner colorbar
              outlinewidth: 0,
              x: 0.95, // Move colorbar inward
              xpad: 10,
            },
          },
          paper_bgcolor: "rgb(3, 7, 36)",
          plot_bgcolor: "rgb(3, 7, 36)",
          width: 800,
          height: 800,
          autosize: true,
        };

        // Adjust marker properties
        if (result.data[0].type === "scatter3d") {
          result.data[0].marker = {
            ...result.data[0].marker,
            size: 5, // Slightly smaller markers
            opacity: 0.8,
            line: {
              width: 0.5,
              color: "rgba(255, 255, 255, 0.3)",
            },
          };
        }
      }

      return {
        data: result.data,
        layout: result.layout,
        is3DChart,
      };
    } catch (e) {
      console.error("Error parsing chart data:", e);
      return null;
    }
  };

  const initializeChart = useCallback(
    async (chartKey, chartContainer, chartData) => {
      if (initializedCharts.current.has(chartKey)) {
        return Promise.resolve();
      }

      try {
        const config = {
          displayModeBar: true,
          responsive: true,
          displaylogo: false,
          modeBarButtonsToAdd: chartData.is3DChart
            ? ["hoverClosestGl3d"]
            : ["hoverClosestGl2d", "hoverCompareCartesian"],
          toImageButtonOptions: {
            format: "png",
            filename: "chart",
            scale: 2,
          },
        };

        // Create the plot
        await Plotly.newPlot(
          chartContainer,
          chartData.data,
          chartData.layout,
          config
        );

        // For 3D charts, wait a bit then trigger a resize to ensure proper rendering
        if (chartData.is3DChart) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          await Plotly.Plots.resize(chartContainer);
        }

        initializedCharts.current.add(chartKey);

        // Return a promise that resolves after the chart is fully rendered
        return new Promise((resolve) => {
          if (chartData.is3DChart) {
            // For 3D charts, add a small delay to ensure WebGL context is ready
            setTimeout(() => {
              Plotly.Plots.resize(chartContainer);
              resolve();
            }, 300);
          } else {
            resolve();
          }
        });
      } catch (error) {
        console.error(`Error initializing chart ${chartKey}:`, error);
        return Promise.reject(error);
      }
    },
    []
  );
  // Add this effect to handle cleanup properly
  useEffect(() => {
    const currentRefs = chartRefs.current;

    return () => {
      Object.entries(currentRefs).forEach(([key, ref]) => {
        if (ref.current) {
          Plotly.purge(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    messages.forEach((message, index) => {
      if (message.senderType === "AI" && message.content.includes("<script>")) {
        const chartKey = `chart-${index}`;
        const chartData = extractChartData(message.content);

        if (!chartRefs.current[chartKey]) {
          chartRefs.current[chartKey] = React.createRef();
        }

        const chartContainer = chartRefs.current[chartKey]?.current;
        if (chartContainer && chartData) {
          chartContainer.setAttribute("data-chart-key", chartKey);

          if (!initializedCharts.current.has(chartKey)) {
            initializeChart(chartKey, chartContainer, chartData).then(() => {
              // Force a small delay before marking the chart as initialized
              setTimeout(
                () => {
                  initializedCharts.current.add(chartKey);
                },
                chartData.is3DChart ? 200 : 0
              );
            });
          }

          resizeObserver.observe(chartContainer);
        }
      }
    });

    return () => {
      Object.entries(chartRefs.current).forEach(([key, ref]) => {
        if (ref.current) {
          resizeObserver.unobserve(ref.current);
          Plotly.purge(ref.current);
        }
      });
      initializedCharts.current.clear();
    };
  }, [messages, initializeChart]);

  const renderMessageContent = (message, index) => {
    if (message.senderType === "AI" && message.content.includes("<script>")) {
      const parts = message.content.split(/<script>[\s\S]*?<\/script>/);
      const chartKey = `chart-${index}`;
      const chartData = extractChartData(message.content);

      if (!chartRefs.current[chartKey]) {
        chartRefs.current[chartKey] = React.createRef();
      }

      const showLoader = !chartData;

      return (
        <div className="space-y-4">
          {/* Text before chart */}
          {parts[0] && <div dangerouslySetInnerHTML={{ __html: parts[0] }} />}

          {/* Chart or loader */}
          <div className="relative w-full flex justify-center">
            {showLoader ? (
              <div className="flex items-center justify-center space-x-2 my-8">
                <Loader2 className="h-6 w-6 animate-spin text-primary" />
                <span className="text-primary">
                  Ereky is generating your chart...
                </span>
              </div>
            ) : (
              <>
                <div
                  ref={chartRefs.current[chartKey]}
                  className={`w-[100vw] max-h-[100vh] relative sm:w-[800px] sm:h-[600px] sm:max-w-[90vw] bg-transparent rounded-lg overflow-hidden p-0 my-8 mx-auto
    ${chartData?.is3DChart ? "h-[800px] p-5" : ""}`}
                >
                  {!isChartFullScreen && (
                    <button
                      onClick={() => onSetChartFullScreen()}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "transparent",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        zIndex: 200,
                      }}
                    >
                      <Maximize size={24} />
                    </button>
                  )}

                  {/* Aquí va el contenido del chart */}
                </div>
              </>
            )}
          </div>

          {/* Text after chart - only show when chart is ready */}
          {!showLoader && parts[1] && (
            <div dangerouslySetInnerHTML={{ __html: parts[1] }} />
          )}
        </div>
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: message.content }} />;
  };
  return (
    <>
      <div
        className={`border-b border-border bg-card py-4 px-6  transition-all duration-300 ease-in-out   ${
          isChartFullScreen
            ? "-translate-y-full h-0 overflow-hidden"
            : "translate-y-0"
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={toggleSidebar}
              className="mr-2 text-muted-foreground hover:text-foreground"
            >
              <Menu className="h-5 w-5" />
            </Button>
            <h1 className="text-xl md:text-2xl font-bold flex items-center text-foreground">
              <Sparkles className="mr-2 h-5 w-5 md:h-6 md:w-6 text-primary" />
              Cultzyme AI Agent
            </h1>
          </div>
          <ChatContext
            accessToken={accessToken}
            chatId={selectedChat?.chatId}
          />
        </div>
      </div>
      <ScrollArea
        ref={scrollAreaRef}
        className="flex-grow px-6 py-4"
        onScrollCapture={handleScroll}
      >
        {isChartFullScreen && (
  <button
    onClick={() => onSetChartFullScreen()}
    className="absolute top-2 right-2  bg-secondary text-secondary-foreground hover:bg-primary hover:text-primary-foreground text-black font-medium px-4 py-2 rounded-lg shadow-md cursor-pointer z-50 flex items-center gap-2"
  >
    {/* <X size={24} /> */}
    Exit Full Screen
  </button>
)}
        {isInitialLoad && isChatLoading ? (
          <div className="text-center text-muted-foreground py-4">
            <Loader2 className="h-6 w-6 animate-spin mx-auto" />
            <p className="mt-2">Loading chat messages...</p>
          </div>
        ) : messages.length === 0 ? (
          <div className="text-center text-muted-foreground py-4">
            <p>No messages available.</p>
          </div>
        ) : (
          messages.map((message, index) => (
            <div
              key={message.messageId}
              className={`flex ${
                message.senderType === "USER" ? "justify-end" : "justify-start"
              } mb-4`}
            >
              <div
                className={`flex items-start max-w-[80%] sm:max-w-[70%] ${
                  message.senderType === "USER"
                    ? "flex-row-reverse"
                    : "flex-row"
                }`}
              >
                <Avatar className="w-8 h-8 mt-1">
                  <AvatarImage
                    src={
                      message.senderType === "USER"
                        ? "/user-avatar.png"
                        : "/bot-avatar.png"
                    }
                  />
                  <AvatarFallback>
                    {message.senderType === "USER" ? "U" : "AI"}
                  </AvatarFallback>
                </Avatar>
                <div
                  className={`mx-2 p-3 rounded-lg ${
                    message.senderType === "USER"
                      ? "bg-primary text-primary-foreground"
                      : "bg-secondary text-secondary-foreground"
                  }`}
                >
                  {renderMessageContent(message, index)}
                </div>
              </div>
            </div>
          ))
        )}
        {isTyping && (
          <div className="flex items-center text-muted-foreground">
            <Loader2 className="h-4 w-4 animate-spin mr-2" />
            <p className="italic">Ereky is writing...</p>
          </div>
        )}
      </ScrollArea>
      {showScrollButton && !isChartFullScreen && (
        <Button
          className="fixed bottom-44 left-[60%] bg-card transform -translate-x-1/2 w-8 h-8 rounded-full shadow-lg"
          size="icon"
          onClick={scrollToBottom}
        >
          <ArrowDown className="h-4 w-4 text-muted-foreground" />
        </Button>
      )}
    </>
  );
}
