import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/UI/Shadcn/Card";
import {
  Loader2,
  AlertCircle,
  ChevronRight,
  Trash2,
  ArrowUpDown,
  AlertTriangleIcon,
} from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/UI/Shadcn/Table";
import { Button } from "../../components/UI/Shadcn/Button";
import { Badge } from "../../components/UI/Shadcn/Badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/UI/Shadcn/Select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/UI/Shadcn/Tooltip";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "../../components/UI/Shadcn/Pagination";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/UI/Shadcn/Dialog";
import { useToast } from "../../hooks/use-toast";
import { deleteTraining, getTrainingsData } from "../../service";

const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

export const getTrainingStatus = (state) => {
  switch (state) {
    case "SEARCH":
      return {
        trainingStatus: "Searching data",
        statusDescription: "Searching for data in the database",
      };
    case "SEARCH_FAILED":
      return {
        trainingStatus: "Search failed",
        statusDescription: "Please try again reentering the data",
      };

    case "IMPACT_FACTOR":
      return {
        trainingStatus: "Impact factors generated",
        statusDescription: "You are ready to generate the experiments",
      };
    case "GENERATE_EXPERIMENTS":
      return {
        trainingStatus: "Generating DOES",
        statusDescription: "Your experiments are being generated",
      };

    case "GENERATE_EXPERIMENTS_FAILED":
      return {
        trainingStatus: "Experiments generation failed",
        statusDescription: "Please try again reentering the data",
      };

    case "DESIGN":
      return {
        trainingStatus: "Ready for designs",
        statusDescription:
          "You are ready to generate the Design of Experiments",
      };
    default:
      return "Unknown status";
  }
};


export default function Trainings() {
  const [trainings, setTrainings] = useState([]);
  const [noTrainingModal, setNoTrainingModal] = useState(false);
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [
    trainingInProcessSelectedToDelete,
    setTrainingInProcessSelectedToDelete,
  ] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false); // Nuevo estado para alternar entre los modos normal y de eliminación múltiple
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    trainingId: null,
  });
  const [sortConfig, setSortConfig] = useState({
    key: "startDate",
    direction: "desc",
  });
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const accessToken = useSelector(selectCurrentToken);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { toast } = useToast();

  const getTrainingNames = (trainingsIds) => {
    return trainingsIds.map((trainingId) => {
      const training = trainings.find(
        (training) => training.trainingDoeId === trainingId
      );
      return training.trainingDoeName;
    });
  };

  const deletedTrainingNames = getTrainingNames(selectedTrainings);

 

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const data = await getTrainingsData();
        if (data) {
          setNoTrainingModal(data.length === 0);
          setTrainings(data);
          return;
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainings();
  }, [accessToken]);

  const sortedTrainings = React.useMemo(() => {
    let sortableTrainings = [...trainings];
    if (sortConfig.key) {
      sortableTrainings.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableTrainings;
  }, [trainings, sortConfig]);

  const paginatedTrainings = sortedTrainings.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(sortedTrainings.length / pageSize);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handlePageSizeChange = (value) => {
    setPageSize(Number(value));
    setCurrentPage(1);
  };

  const openTrainingDetails = (trainingDoeId) => {
    navigate(`/trainings/${trainingDoeId}`);
  };

  // const confirmDelete = (trainingDoeId) => {
  //   setDeleteConfirmation({ isOpen: true, trainingId: trainingDoeId });
  // };

  const cancelDelete = () => {
    setDeleteConfirmation({ isOpen: false, trainingId: null });
  };

  const toggleSelectTraining = (trainingDoeId) => {
    const newSelectedTrainings = selectedTrainings.includes(trainingDoeId)
      ? selectedTrainings.filter((id) => id !== trainingDoeId)
      : [...selectedTrainings, trainingDoeId];
    setSelectedTrainings(newSelectedTrainings);

    const hasSelectedTrainingsSomeProcess = trainings.some((training) => {
      const isStateInProcess =
        training.state === "SEARCH" ||
        training.state === "GENERATE_EXPERIMENTS";
      return (
        newSelectedTrainings.includes(training.trainingDoeId) &&
        isStateInProcess
      );
    });

    if (hasSelectedTrainingsSomeProcess) {
      setTrainingInProcessSelectedToDelete(true);
    } else if (!hasSelectedTrainingsSomeProcess) {
      setTrainingInProcessSelectedToDelete(false);
    }

    if (newSelectedTrainings.length === 0) {
      setDeleteMode(false);
    }
  };

  const deleteSelectedTrainings = async () => {
    setIsDeleting(true);
    const bodySelectedTrainings = selectedTrainings.map((trainingId) => ({
      trainingDoeId: trainingId,
    }));
    const remainingTrainings = trainings.filter((training) => {
      const isTrainingSelected = selectedTrainings.includes(
        training.trainingDoeId
      );
      return !isTrainingSelected && training;
    });

    try {
      await deleteTraining(bodySelectedTrainings);
      setSelectedTrainings([]);
      setDeleteMode(false);
      setTrainings(remainingTrainings);
      if (remainingTrainings.length === 0) {
        setNoTrainingModal(true);
      }

      toast({
        title: "Training Deleted",
        description: "The training has been successfully deleted.",
        variant: "success",
      });
    } catch (err) {
      console.error(err);

      setError(err.message);
      toast({
        title: "Error",
        description: "Failed to delete the training. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
      setDeleteConfirmation({ isOpen: false, trainingId: null });
    }
  };


  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-5 w-5" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  const SortableHeader = ({ column, label }) => (
    <TableHead>
      <button
        onClick={() => requestSort(column)}
        className="inline-flex items-center gap-1 hover:text-primary transition-colors"
      >
        {label}
        <ArrowUpDown className="h-4 w-4" />
      </button>
    </TableHead>
  );

  return (
    <>
      {noTrainingModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[5] mt-[60px]">
          <Card className="sm:max-w-[425px] max-w-[90vw] mx-auto border shadow-md bg-background">
            <CardHeader>
              <CardTitle>
                <h2 className="text-xl font-semibold">
                  Welcome to Cultzyme AI Trainings
                </h2>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center space-x-4 my-4">
                <p className="text-sm text-muted-foreground">
                  You don't have any trainings yet. To create a new training,{" "}
                  <br /> go to{" "}
                  <Link
                    to="/insilico"
                    className="text-blue-500 hover:text-blue-700 underline"
                  >
                    In Silico
                  </Link>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <div className="container mx-auto p-4 md:p-6 lg:p-8">
        <Card className="mt-6">
          <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div>
              <CardTitle>Trainings</CardTitle>
              <CardDescription className="pt-2">
                Overview of all your training sessions
              </CardDescription>
            </div>
            <div className="flex items-center gap-2 w-full  sm:w-auto">
              <span className="text-sm text-muted-foreground">Show</span>

              <Select
                value={pageSize.toString()}
                onValueChange={handlePageSizeChange}
              >
                <SelectTrigger className="w-[100px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {PAGE_SIZE_OPTIONS.map((size) => (
                    <SelectItem key={size} value={size.toString()}>
                      {size}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div className="flex gap-2 ml-auto mr-4">
                {deleteMode && (
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedTrainings([]);
                      setDeleteMode(false);
                    }}
                    className="px-6 py-2 rounded-lg  bg-background border border-gray-300 shadow-md transition duration-200 ease-in-out"
                  >
                    Cancel
                  </Button>
                )}
                {deleteMode && selectedTrainings.length > 0 && (
                  <>
                    <Button
                      variant="destructive"
                      onClick={() =>
                        setDeleteConfirmation({
                          isOpen: true,
                          trainingId: selectedTrainings,
                        })
                      }
                      disabled={isDeleting || trainingInProcessSelectedToDelete}
                      className={`px-6 py-2 rounded-lg text-white bg-red-600 hover:bg-red-700 border border-red-700 shadow-md transition duration-200 ease-in-out ${
                        isDeleting ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      Delete All
                    </Button>
                    {trainingInProcessSelectedToDelete && (
                      <p className="text-sm text-gray-500 mt-2 absolute translate-y-[190%]  translate-x-[-13%]">
                        Trainings in progress cannot be deleted.
                      </p>
                    )}
                  </>
                )}{" "}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {paginatedTrainings.length === 0 ? (
              <div className="text-center py-6">
                <h3 className="text-2xl font-semibold mb-2">
                  No Trainings Available
                </h3>
                <p className="text-muted-foreground">
                  You currently don't have any trainings. Trainings will appear
                  here once they are created.
                </p>
              </div>
            ) : (
              <>
                <div className="overflow-auto">
                  <Table className="w-full">
                    <TableHeader>
                      <TableRow>
                        <SortableHeader column="trainingDoeName" label="Name" />
                        <SortableHeader column="startDate" label="Start Date" />
                        <SortableHeader column="finishDate" label="Status" />
                        <SortableHeader
                          column="processTypeDescription"
                          label="Process Type"
                        />
                        <SortableHeader
                          column="aimTypeDescription"
                          label="Aim Type"
                        />
                        <TableHead className="text-right">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {paginatedTrainings.map((training) => {
                        const trainingState = training.state;
                        const { trainingStatus, statusDescription } =
                          getTrainingStatus(trainingState);
                        return (
                          <TableRow key={training.trainingDoeId}>
                            <TableCell className="font-medium truncate">
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <span className="block truncate ">
                                      {training.trainingDoeName}
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>{training.trainingDoeName}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell className="max-w-[100px] sm:max-w-[150px] truncate">
                              {new Date(
                                training.startDate
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell className=" truncate">
                              {training.finishDate ? (
                                <Badge
                                  variant="outline"
                                  className="bg-green-50 text-green-700 border-green-200"
                                >
                                  Completed
                                </Badge>
                              ) : (
                                <Badge
                                  variant="outline"
                                  className="bg-blue-50 text-blue-700 border-blue-200"
                                >
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger>
                                        {" "}
                                        {trainingStatus}
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>{statusDescription}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </Badge>
                              )}
                            </TableCell>
                            <TableCell className=" truncate">
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <span className="block truncate">
                                      {training.processTypeDescription}
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>{training.processTypeDescription}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell className=" truncate">
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <span className="block truncate ">
                                      {training.aimTypeDescription}
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>{training.aimTypeDescription}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </TableCell>
                            <TableCell className="text-right whitespace-nowrap">
                              <div className="flex justify-end space-x-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() =>
                                    openTrainingDetails(training.trainingDoeId)
                                  }
                                  className="hidden sm:inline-flex"
                                >
                                  View
                                  <ChevronRight className="ml-2 h-4 w-4" />
                                </Button>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => {
                                    openTrainingDetails(training.trainingDoeId);
                                  }}
                                  className="sm:hidden"
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>

                                {deleteMode ? (
                                  <Button size="icon">
                                    <input
                                      className="h-4 w-5 self-center "
                                      type="checkbox"
                                      checked={selectedTrainings.includes(
                                        training.trainingDoeId
                                      )}
                                      onChange={() =>
                                        toggleSelectTraining(
                                          training.trainingDoeId
                                        )
                                      }
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    variant="destructive"
                                    size="icon"
                                    onClick={() => {
                                      toggleSelectTraining(
                                        training.trainingDoeId
                                      );
                                      setDeleteMode(true);
                                    }}
                                  >
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-4">
                  <div className="text-sm text-muted-foreground order-2 sm:order-1">
                    Showing {(currentPage - 1) * pageSize + 1} to{" "}
                    {Math.min(currentPage * pageSize, sortedTrainings.length)}{" "}
                    of {sortedTrainings.length} entries
                  </div>
                  <Pagination className="order-1 sm:order-2">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((p) => Math.max(1, p - 1))
                          }
                          disabled={currentPage === 1}
                        />
                      </PaginationItem>
                      <PaginationItem>
                        <Select
                          value={currentPage.toString()}
                          onValueChange={(value) =>
                            setCurrentPage(Number(value))
                          }
                        >
                          <SelectTrigger className="w-[70px]">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {[...Array(totalPages)].map((_, i) => (
                              <SelectItem
                                key={i + 1}
                                value={(i + 1).toString()}
                              >
                                {i + 1}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationNext
                          onClick={() =>
                            setCurrentPage((p) => Math.min(totalPages, p + 1))
                          }
                          disabled={currentPage === totalPages}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              </>
            )}
          </CardContent>
        </Card>

        <Dialog open={deleteConfirmation.isOpen} onOpenChange={() => {}}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete the following trainings?
                <ul className="list-disc pl-5 text-sm text-muted-foreground flex flex-col  items-center sm:items-start">
                  {deletedTrainingNames.map((name) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </DialogDescription>
              <DialogDescription className="flex items-center text-red-400 px-6 pr-12 w-[90%] ml-auto">
                <AlertTriangleIcon className="mr-2 h-5 w-5" />
                <span className="text-start">
                  All data related to these trainings, chats, and information
                  will be lost permanently.
                </span>
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={cancelDelete}
                disabled={isDeleting}
              >
                Cancel
              </Button>
              <Button
                variant="destructive"
                onClick={deleteSelectedTrainings}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Deleting...
                  </>
                ) : (
                  "Delete All"
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
