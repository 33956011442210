import * as React from "react";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "../../UI/Shadcn/Sidebar";
import { Link } from "react-router-dom";
import cultzymeLogoDark from "../../../assets/images/ctz-logo-white-black.png";
import cultzymeLogoLight from "../../../assets/images/cultzyme-short-logo.png";
import { useTheme } from "../../ThemeProvider";

export function TeamSwitcher() {
  const { theme } = useTheme();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <Link to="/bions-management-app/bions" className="mt-4 mb-6">
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          >
            <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
              <img
                src={theme === "dark" ? cultzymeLogoLight : cultzymeLogoDark}
                alt="Cultzyme-Logo"
                className="w-8 h-8"
              />
            </div>
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">CULTZYME</span>
              <span className="truncate text-xs">Intelligent bioprocessing.</span>
            </div>
          </SidebarMenuButton>
        </Link>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
