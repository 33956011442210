import React, { useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../UI/Shadcn/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
// import { useGetTrainingQuery } from "../../app/api/aiApiSlice";
import { getAgentChat, getTrainingData } from "../../service";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";

const BreadcrumbLoader = () => {
  return (
    <div className="w-4 h-4 border-2 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
  );
};

const BreadcrumbComponent = () => {
  const [chatName, setChatName] = React.useState("");
  const [trainingName , setTrainingName] = React.useState("");
  const [isChatNameLoading, setIsChatNameLoading] = React.useState(false);
  const location = useLocation();
  const pathParts = location.pathname.split("/").filter((part) => part);
  const trainingId = pathParts.includes("trainings")
    ? pathParts[pathParts.indexOf("trainings") + 1]
    : null;
  const chatId = pathParts.includes("chatbot")
    ? pathParts[pathParts.indexOf("chatbot") + 1]
    : null;


  useEffect(() => {
    if (!chatId) {
      setChatName("Chatbot");
      return;
    }
    setIsChatNameLoading(true);
    getAgentChat(chatId).then((res) => {
      if (res.error) {
        console.error(res.error);
        return;
      }
      setChatName(res.data.preview);
      setIsChatNameLoading(false);
    });
  }, [ chatId]);

  useEffect(() => {
    if (!trainingId) {
      setChatName("Training");
      return;
    }
    setIsChatNameLoading(true);
    getTrainingData(trainingId).then((res) => {
      if (res.error) {
        console.error(res.error);
        return;
      }
      const trainingName = res.trainingDoeName;
      setTrainingName(trainingName);
      setIsChatNameLoading(false);
    });
  }, [ trainingId]);


  const getBreadcrumbText = (locationText, index) => {
    switch (locationText) {
      case "bions-management-app":
        return "Bions Dashboard";
      case "bions":
        return "Bions";
      case "new-files":
        return "Cultzyme AI";
      case "settings":
        return "Settings";
      case "trainings":
        return "Trainings";
      case "chatbot":
        return "Chatbot";
      case "home":
        return "Home";
      case "insilico":
        return "In Silico";
      default:
        return decodeURIComponent(locationText);
    }
  };

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {pathParts.map((part, index) => {
          const isLast = index === pathParts.length - 1;
          const path = `/${pathParts.slice(0, index + 1).join("/")}`;
          const text = getBreadcrumbText(part, index);
          let breadCrumLastPage = text;

          const isTrainingIdOnPath = pathParts.includes("trainings")
            ? pathParts[pathParts.indexOf("trainings") + 1]
            : null;
          if (!trainingName && isTrainingIdOnPath) {
            breadCrumLastPage = <BreadcrumbLoader />;
          }
          if (trainingName && isTrainingIdOnPath) {
            breadCrumLastPage = trainingName || "Training";
          }

          const isChatIdOnPath = pathParts.includes("chatbot");
          if (chatName === "" && isChatIdOnPath ) {
            breadCrumLastPage = <BreadcrumbLoader />;
          }
          if (chatName && isChatIdOnPath) {
            breadCrumLastPage = chatName;
          }
          if (isChatNameLoading) {
            breadCrumLastPage = <BreadcrumbLoader />;
          }
          
          return (
            <React.Fragment key={path}>
              <BreadcrumbItem>
                {!isLast ? (
                  <BreadcrumbLink>
                    <Link to={path}>{text}</Link>
                  </BreadcrumbLink>
                ) : (
                  <BreadcrumbPage>{breadCrumLastPage}</BreadcrumbPage>
                )}
              </BreadcrumbItem>
              {!isLast && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
