import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../UI/Shadcn/Card";
import { Button } from "../../UI/Shadcn/Button";
import { Badge } from "../../UI/Shadcn/Badge";
import {
  useDisconnectUserFromBionMutation,
  usePutBionToSteriliseModeMutation,
} from "../../../features/bion/bionApiSlice";
import Timer from "../Timer/Timer";
import { HiStatusOnline, HiStatusOffline } from "react-icons/hi";
import { MdCleaningServices } from "react-icons/md";
import { VscDebugDisconnect } from "react-icons/vsc";
import { IoEnter } from "react-icons/io5";
import { RiForbid2Fill } from "react-icons/ri";

const BionCard = ({ bion }) => {
  const [disconnectUserFromBionReq] = useDisconnectUserFromBionMutation();
  const [putBionToSteriliseModeReq] = usePutBionToSteriliseModeMutation();

  const disconnectFromBionHandler = () => {
    disconnectUserFromBionReq(bion.bionId);
  };

  const putBionToSteriliseModeHandler = () => {
    putBionToSteriliseModeReq({
      bionId: bion.bionId,
      steriliseActive: bion.bionMode !== "STERILISE_ACTIVE",
    });
  };

  const renderContent = () => {
    //NOTE - 2024-12-19T15:36:16.400Z La fecha llega en este formato
    // console.log(bion.shutDownDate);
    // const TestDate = new Date('2024-12-19T16:10:16.400Z');
    // TestDate.setMinutes(TestDate.getMinutes() + 10);
    const date = new Date(bion.shutDownDate);
    date.setMinutes(date.getMinutes() + 10);

    const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
    const lastConection = bion.shutDownDate ? bion.shutDownDate : tenMinutesAgo;

    if (!bion.isOn) {
      return (
        <div className="space-y-2">
          <p className="text-sm text-muted-foreground">
            We're <span className="font-semibold">actively working</span> to
            reconnect your bioreactor. If it doesn't regain connection{" "}
            <span className="font-semibold">within 10 minutes</span>, you'll be{" "}
            <span className="font-semibold">automatically disconnected</span>.
            Thank you for your patience.
          </p>
          <Timer shutDownDate={lastConection.getTime()} />
        </div>
      );
    }

    switch (bion.bionMode) {
      case "PROCESS_ACTIVE":
        return (
          <div className="space-y-2">
            <h4 className="font-semibold">Active Process: Normal Mode</h4>
            <p className="text-sm text-muted-foreground">
              Your Bion is currently executing a{" "}
              <span className="font-semibold">normal process</span>. While it's
              in progress, you have the option to access your Bion and configure
              or inspect the process details.
            </p>
          </div>
        );
      case "REPLICATE_ACTIVE":
        return (
          <div className="space-y-2">
            <h4 className="font-semibold">Active Process: Replication Mode</h4>
            <p className="text-sm text-muted-foreground">
              Your Bion is currently undergoing a{" "}
              <span className="font-semibold">replication process</span>. During
              this process, you can access your Bion to monitor the replication
              progress, terminate it if needed, or check the remaining time for
              completion.
            </p>
          </div>
        );
      case "STERILISE_ACTIVE":
        const date = new Date(bion.sterilizationDate);
        date.setMinutes(date.getMinutes() + 60);
        return (
          <div className={`space-y-2 ${!bion.isLidSet ? "opacity-50" : ""}`}>
            <h4 className="font-semibold">Sterilising...</h4>
          </div>
        );
      default:
        return (
          <div className="space-y-2">
            <h4 className="font-semibold">No active process running.</h4>
            <p className="text-sm text-muted-foreground">
              You have the <span className="font-semibold">opportunity</span> to
              initiate a <span className="font-semibold">new process</span> on
              this Bion. Get started now!
            </p>
          </div>
        );
    }
  };

  return (
    <Card className="w-full bg-card">
      <CardHeader>
        <CardTitle className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
          <span className="text-xl">{bion.bionName}</span>
          <Badge
            variant={bion.isOn ? "default" : "secondary"}
            className="text-xs whitespace-nowrap"
          >
            {bion.isOn ? (
              <>
                <HiStatusOnline className="mr-1 inline-block" /> Online
              </>
            ) : (
              <>
                <HiStatusOffline className="mr-1 inline-block" /> Offline
              </>
            )}
          </Badge>
        </CardTitle>
        <p className="text-sm text-muted-foreground">
          Lid State:{" "}
          <span className={bion.isLidSet ? "text-green-500" : "text-red-500"}>
            {bion.isLidSet ? "Closed" : "Opened"}
          </span>
        </p>
      </CardHeader>
      <CardContent>{renderContent()}</CardContent>
      {bion.isOn && (
        <CardFooter className="flex flex-wrap gap-2">
          <Button
            variant="outline"
            size="sm"
            asChild
            className="flex-1 min-w-[120px] transition-colors duration-200 hover:bg-primary hover:text-primary-foreground"
            disabled={bion.bionMode === "STERILISE_ACTIVE"}
          >
            <Link
              to={
                bion.bionMode === "STERILISE_ACTIVE"
                  ? "#"
                  : `/bions-management-app/bions/${bion.bionId}`
              }
            >
              <IoEnter className="mr-2 inline-block" />
              Enter Bion
              {bion.bionMode === "STERILISE_ACTIVE" && (
                <RiForbid2Fill className="ml-2 inline-block" />
              )}
            </Link>
          </Button>
          {(bion.bionMode === "STERILISE_ACTIVE" ||
            bion.bionMode === null ||
            !bion.bionMode) && (
            <Button
              variant="outline"
              size="sm"
              onClick={putBionToSteriliseModeHandler}
              className="flex-1 min-w-[120px] transition-colors duration-200 hover:bg-primary hover:text-primary-foreground"
            >
              <MdCleaningServices className="mr-2 inline-block" />
              Sterilise
              <span
                className={`ml-2 ${
                  bion.bionMode === "STERILISE_ACTIVE"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {bion.bionMode === "STERILISE_ACTIVE" ? "ON" : "OFF"}
              </span>
            </Button>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={disconnectFromBionHandler}
            disabled={bion.bionMode === "STERILISE_ACTIVE"}
            className="flex-1 min-w-[120px] transition-colors duration-200 hover:bg-destructive hover:text-destructive-foreground"
          >
            <VscDebugDisconnect className="mr-2 inline-block" />
            Disconnect
            {bion.bionMode === "STERILISE_ACTIVE" && (
              <RiForbid2Fill className="ml-2 inline-block" />
            )}
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default BionCard;
