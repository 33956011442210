import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import { apiSlice } from "../app/api/apiSlice";
import { useToast } from "../hooks/use-toast";
import ChatbotSidebar from "./Chatbot/ChatbotSidebar";
import ChatbotBody from "./Chatbot/ChatbotBody";
import ChatbotInput from "./Chatbot/ChatbotInput";
import { marked } from "marked";
import { Card, CardContent, CardHeader, CardTitle } from "./UI/Shadcn/Card";
import {
  deleteAgentChat,
  getAgentChat,
  getAgentChats,
  getAgentSuggestions,
  sendAgentMessage,
} from "../service";
import { Button } from "./UI/Shadcn/Button";
import axios from "axios";

export default function ChatbotContainer() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [temperature, setTemperature] = useState(0.5);
  const [usePublicData, setUsePublicData] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [dynamicSuggestions, setDynamicSuggestions] = useState([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [isChartFullScreen, setIsChartFullScreen] = useState(false);
  const [noChatsModal, setNoChatsModal] = useState(false);

  const dispatch = useDispatch();
  let accessToken = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!accessToken);
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (!accessToken && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }
    setTokenExisted(!!accessToken);
  }, [accessToken, dispatch, tokenExisted]);

  useEffect(() => {
    if (accessToken) {
      getChats();
    }
  }, [accessToken]);

  useEffect(() => {
    if (chatId && chats.length > 0) {
      const chat = chats.find((c) => c.chatId === chatId);
      if (chat) {
        selectChat(chat);
        setIsLoading(false);
      }
    }
  }, [chatId, chats]);

  const getChats = useCallback(async () => {
    try {
      setIsSidebarLoading(true);
      const response = await getAgentChats();
      console.log(response);
      const sortedData = response.data.sort(
        (a, b) => new Date(b.lastActivity) - new Date(a.lastActivity)
      );
      setChats(sortedData);
      if (response.data.length === 0) {
        setNoChatsModal(true);
      }
    } catch (err) {
      console.log(err);
      toast({
        title: "Error",
        description: "Error fetching chats... please try again later",
        variant: "destructive",
      });
    } finally {
      setIsSidebarLoading(false);
    }
  }, [toast]);

  const deleteChat = async (chatId, event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      setIsSidebarLoading(true);

      await deleteAgentChat(chatId);
      const remainingChats = chats.filter((chat) => chat.chatId !== chatId);
      setChats(remainingChats);
      if (remainingChats.length > 0) {
        const firstRemainingChat = remainingChats[0];
        selectChat(firstRemainingChat);
      } else if (remainingChats.length === 0) {
        setNoChatsModal(true);
      }
      if (selectedChat?.chatId === chatId) {
        setSelectedChat(null);
        setMessages([]);
      }
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsSidebarLoading(false);
    }
  };



  const selectChat = async (chat) => {
    setIsChatLoading(true);
    setSelectedChat(chat);
    navigate(`/chatbot/${chat.chatId}`);
    if (chat.userAiMessages && chat.userAiMessages.length > 0) {
      setMessages(chat.userAiMessages);
      setIsChatLoading(false);
    } else {
      try {
        const response = await getAgentChat(chat.chatId);
        const data = response.data;

        if (data.userAiMessages.length !== 0) {
          const lastAIMessage =
            data.userAiMessages[data.userAiMessages.length - 1].content || "";
          await generateSuggestions(lastAIMessage);
        } else {
          setDynamicSuggestions([]);
        }

        setMessages(data.userAiMessages || []);
      } catch (err) {
        toast({
          title: "Error",
          description: err.message,
          variant: "destructive",
        });
      } finally {
        setIsChatLoading(false);
      }
    }
  };

  const exportChat = () => {
    const chatData = JSON.stringify(messages, null, 2);
    const blob = new Blob([chatData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `chat_${selectedChat?.chatId}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const sendMessage = async (inputMessage) => {
    if (!inputMessage.trim() || !selectedChat) return;

    try {
      setIsLoading(true);
      setIsTyping(true);
      setDynamicSuggestions([]);

      const userMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "USER",
        content: inputMessage,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const response = await sendAgentMessage(
        inputMessage,
        selectedChat,
        temperature,
        usePublicData
      );

      const reader = response.body.getReader();
      let aiResponse = "";
      const decoder = new TextDecoder();

      let tempAiMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "AI",
        content: "",
      };

      setMessages((prevMessages) => [...prevMessages, tempAiMessage]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        for (const line of lines) {
          if (line.startsWith("data:")) {
            aiResponse += line.slice(5);

            tempAiMessage.content = marked(aiResponse);

            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
              )
            );
          }
        }
      }

      tempAiMessage.content = marked(
        aiResponse || "Sorry, I was unable to generate a response."
      );
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
        )
      );

      // Aquí se asegura de no cambiar el preview por el mensaje del usuario
      const updatedChat = {
        ...selectedChat,
        preview: selectedChat.preview || "Chat sin título", // Usa un valor fijo o manten el valor original
        lastActivity: new Date().toISOString(),
      };
      setChats((prevChats) => {
        const filteredChats = prevChats.filter(
          (chat) => chat.chatId !== selectedChat.chatId
        );
        return [updatedChat, ...filteredChats];
      });
      setSelectedChat(updatedChat);
      // Generar sugerencias basadas en la respuesta de AI
      await generateSuggestions(aiResponse);
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
      const errorMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "SYSTEM",
        content: "Error sending the message. Please try again.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const generateSuggestions = async (aiResponse) => {
    try {
      setIsSuggestionsLoading(true);
      const response = await getAgentSuggestions(aiResponse);
      setDynamicSuggestions(response.suggestions);
    } catch (error) {
      console.error("Error generating suggestions:", error);
      setDynamicSuggestions([]);
    } finally {
      setIsSuggestionsLoading(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleChartFullScreen = () => {
    setIsChartFullScreen(!isChartFullScreen);
  }

  return (
    <div className="flex  bg-background text-foreground overflow-hidden">
      {noChatsModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-[5]">
          <Card className="max-w-[425px] mx-auto border shadow-md bg-background">
            <CardHeader>
              <CardTitle>
                <h2 className="text-xl font-semibold">
                  Welcome to Cultzyme AI
                </h2>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center space-x-4 my-4">
                <p className="text-sm text-muted-foreground">
                  You don't have any chats yet. To create a new chat, <br /> go
                  to your{" "}
                  <Link
                    to="/trainings"
                    className="text-blue-500 hover:text-blue-700 underline"
                  >
                    trainings
                  </Link>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <ChatbotSidebar
        chats={chats}
        selectedChat={selectedChat}
        isLoading={isSidebarLoading}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        selectChat={selectChat}
        deleteChat={deleteChat}
        exportChat={exportChat}
        isChartFullScreen={isChartFullScreen}
      />
      <div
        className="flex-1 flex flex-col overflow-hidden"
        style={{
          height: "calc(100vh - 64px)",
        }}
      >
        <ChatbotBody
          selectedChat={selectedChat}
          messages={messages}
          isTyping={isTyping}
          isChatLoading={isChatLoading}
          toggleSidebar={toggleSidebar}
          accessToken={accessToken}
          onSetChartFullScreen={handleChartFullScreen}
          isChartFullScreen={isChartFullScreen}
        />
        <ChatbotInput
          sendMessage={sendMessage}
          isLoading={isLoading}
          isChatLoading={isChatLoading}
          temperature={temperature}
          setTemperature={setTemperature}
          usePublicData={usePublicData}
          setUsePublicData={setUsePublicData}
          dynamicSuggestions={dynamicSuggestions}
          isSuggestionsLoading={isSuggestionsLoading}
          isChartFullScreen={isChartFullScreen}
        />
      </div>
    </div>
  );
}
