import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "../../UI/Shadcn/Sidebar";
import { Home, Settings, ChevronRight, Sparkles } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../UI/Shadcn/Collapsible";

const navMain = [
  {
    title: "Home",
    url: "/home",
    icon: Home,
    isActive: true,
    items: [
      {
        title: "Dashboard",
        url: "/home",
      },
      {
        title: "Bions",
        url: "/bions",
      },
      {
        title: "In Silico",
        url: "/insilico",
      },
    ],
  },

  {
    title: "AI",
    url: "/new-files",
    icon: Sparkles,
    items: [
      {
        title: "General",
        url: "/new-files",
      },
      {
        title: "Agents",
        url: "/chatbot",
      },
      {
        title: "Trainings",
        url: "/trainings",
      },
    ],
  },
  {
    title: "Settings",
    url: "/settings",
    icon: Settings,
    items: [
      {
        title: "General",
        url: "/settings",
      },
    ],
  },
];

export function NavMain() {
  return (
    <>
      <SidebarGroup>
        {/* <SidebarGroupLabel>Platform</SidebarGroupLabel> */}
        <SidebarMenu>
          {navMain.map((item) => (
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={item.isActive}
              className="group/collapsible"
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton tooltip={item.title}>
                    <Link to={item.url}>
                    {item.icon && <item.icon className="w-5 h-5" />}
                    </Link>
                    <span>{item.title}</span>
                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {item.items?.map((subItem) => (
                      <SidebarMenuSubItem key={subItem.title}>
                        <SidebarMenuSubButton asChild>
                          <Link to={subItem.url}>
                            <span>{subItem.title}</span>
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          ))}
        </SidebarMenu>
      </SidebarGroup>
    </>
  );
}
