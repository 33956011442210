import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/UI/Shadcn/Button";
import { useToast } from "../../../hooks/use-toast";
import { Loader2, MessageSquare } from "lucide-react";

export default function CreateChatButton({ training, accessToken }) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const getTrainingData = async () => {
    try {
      const trainingDoeId = training.trainingDoeId;
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/${trainingDoeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch training data.");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching training data:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to fetch training data.",
        variant: "destructive",
      });
      return null;
    }
  };

  const handleCreateChat = async () => {
    if (!training) return;

    setIsLoadingChat(true);
    try {
      const trainingData = await getTrainingData();
      if (!trainingData) {
        throw new Error("Failed to fetch training data.");
      }

      if (trainingData.chatId) {
        console.log("Chat already exists. Redirecting to existing chat...");
        navigate(`/chatbot/${trainingData.chatId}`);
        return;
      }

      setIsCreatingChat(true);
      setIsLoadingChat(false);

      const formData = {
        trainingDoeId: trainingData.trainingDoeId,
        processType: trainingData.processType,
        aimType: trainingData.aimType,
        gmoActivated: trainingData.gmoActivated,
        gmoPurpose: trainingData.gmoPurpose,
        microOrganismType: trainingData.microOrganismType,
        microOrganismDescription: trainingData.microOrganismDescription,
        bioProcessTargetType: trainingData.bioProcessTargetType,
        moleculeType: trainingData.moleculeType,
        moleculeDescription: trainingData.moleculeDescription,
        productionVolume: trainingData.productionVolume,
        bioreactorModel: trainingData.bioreactorModel,
        processMode: trainingData.processMode,
      };

      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/chats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create chat.");
      }

      const result = await response.json();
      console.log("Chat created:", result);
      navigate(`/chatbot/${result.chatId}`);
    } catch (error) {
      console.error("Error creating chat:", error);
      toast({
        title: "Error",
        description:
          error.message || "Failed to create chat. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsCreatingChat(false);
      setIsLoadingChat(false);
    }
  };

  return (
    <Button
      onClick={handleCreateChat}
      disabled={isCreatingChat || isLoadingChat}
      className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded"
    >
      <span className="relative z-10 flex items-center justify-center">
        {isLoadingChat ? (
          <>
            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
            Loading chat...
          </>
        ) : isCreatingChat ? (
          <>
            <Loader2 className="mr-2 h-5 w-5 animate-spin" />
            Creating chat...
          </>
        ) : (
          <>
            <MessageSquare className="mr-2 h-5 w-5" />
            Ask Me Anything
          </>
        )}
      </span>
    </Button>
  );
}
