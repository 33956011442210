import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetUserSettingsQuery } from "../features/user/userApiSlice";
import { getLocalStorageItems } from "../utils/localStorageUtils";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { data } = useGetUserSettingsQuery();
  const localStorageTheme = getLocalStorageItems("theme");
  const initialTheme = localStorageTheme || "light"; 
  const [theme, setTheme] = useState(initialTheme);
  

  useEffect(() => {
   
    if (data && data.themeColor) {
      setTheme(data.themeColor === "DARK" ? "dark" : "light");
    }
  }, [data]);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");
    root.classList.add(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const setDarkTheme = () => {
    setTheme("dark");
  };

  const setLightTheme = () => {
    setTheme("light");
  };


  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, setDarkTheme, setLightTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
