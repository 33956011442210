import {
  getLocalStorageItems,
  updateLocalStorageItems,
} from "../utils/localStorageUtils";

let accessToken;
let isRefreshing = false;
let refreshPromise = null;
const pendingRequests = [];

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const baseFetch = async (url, endpoint, options = {}) => {
  accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    throw new Error("Access token not found. Please log in again.");
  }

  let headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
    ...(options.headers || {}),
  };
  if (options.multipartFormData) {
    headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(options.headers || {}),
    };
  }

  try {
    const response = await fetch(`${url}${endpoint}`, {
      ...options,
      headers,
    });
    console.log(response.status);
    if (response.status === 401) {
      await handleTokenRefresh();
      return baseFetch(url, endpoint, options);
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "An error occurred");
    }

    if (options.returnResponse) {
      return response;
    }

    return response.status === 204 ? null : await response.json();
  } catch (error) {
    console.error(`Error in API call to ${endpoint}:`, error);
    throw error;
  }
};

const handleTokenRefresh = async () => {
  if (isRefreshing) {
    return refreshPromise;
  }

  const refreshToken = getLocalStorageItems("refreshToken");
  if (!refreshToken) {
    redirectToLogin();
    throw new Error("No refresh token available. Please log in again.");
  }

  const userName = getLocalStorageItems("userName");

  isRefreshing = true;
  refreshPromise = refreshTokenRequest(refreshToken, userName)
    .then((data) => {
      updateLocalStorageItems("accessToken", data.accessToken);
      updateLocalStorageItems("refreshToken", data.refreshToken);
      accessToken = data.accessToken; // Actualiza la referencia global
      processPendingRequests(data.accessToken);
    })
    .catch((error) => {
      console.error("Failed to refresh token:", error);
      failPendingRequests(error);
      redirectToLogin();
      throw error;
    })
    .finally(() => {
      isRefreshing = false;
      refreshPromise = null;
    });

  return refreshPromise;
};

const refreshTokenRequest = async (refreshToken, userName) => {
  const response = await fetch(
    `${BACKEND_URL}/cultzyme-api/v1/auth/refreshtoken`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        refreshtoken: refreshToken,
      },
      body: JSON.stringify({ userName }),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to refresh token");
  }

  return await response.json();
};

// Redirige al login
const redirectToLogin = () => {
  if (!window.location.pathname.includes("/login")) {
    window.location.href = "/login";
  }
};

// Manejo de solicitudes pendientes
const addPendingRequest = (request) =>
  new Promise((resolve, reject) => {
    pendingRequests.push({ resolve, reject, request });
  });

const processPendingRequests = (newAccessToken) => {
  pendingRequests.forEach(({ resolve, request }) => {
    if (request) {
      const { url, endpoint, options } = request;
      const updatedOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      };
      resolve(baseFetch(url, endpoint, updatedOptions));
    }
  });
  pendingRequests.length = 0;
};

const failPendingRequests = (error) => {
  pendingRequests.forEach(({ reject }) => reject(error));
  pendingRequests.length = 0;
};

export { baseFetch };
