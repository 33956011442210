//src/app/pusher.js
import Pusher from "pusher-js";

import { getLocalStorageItems } from "../utils/localStorageUtils";
import { getPusherAuth } from "../service";

// Configuration for Pusher
const pusherConfig = {
  // key: "5d0b42250d576ef4eb79",
  // cluster: "eu",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
};

// Variable to store the Pusher instance
let pusher;

export const initializePusher = () => {
  // Create Pusher instance if is not already initialized
  if (!pusher) {
    // Create a new Pusher instance with the specified configuration
    pusher = new Pusher(pusherConfig.key, {
      cluster: "eu",
      channelAuthorization: {
        // Custom authorization handler for Pusher channels
        customHandler: async ({ socketId, channelName }, callback) => {

          try {
            // Make an authentication request to your backend


            const data = getPusherAuth(socketId, channelName);



            // Pass the authentication token back to Pusher
            callback(null, data);
          } catch (error) {
            // Handle any errors that occur during authentication
            callback(error);
          }
        },
      },
    });
  }
};

export const subscribeToChannels = (...subscriptionsAndCallback) => {
  initializePusher(); // Ensure Pusher is initialized

  const callback = subscriptionsAndCallback.pop(); // Remove the last argument as the callback
  const subscriptions = subscriptionsAndCallback; // Assign the rest to subscriptions

  subscriptions.forEach(({ channelName, eventName }) => {
    const channel = pusher.subscribe(channelName);

    channel.bind(eventName, (data) => {
      callback(JSON.parse(data));
    });
  });
};

export const unsubscribeFromChannels = (...channelNames) => {
  channelNames.forEach((channelName) => {
    pusher.unsubscribe(channelName);
    if (pusher?.channels?.[channelName]) {
      // pusher.channels[channelName].unsubscribe();
      // pusher.unsubscribe(channelName);
    }
  });
};

export default {
  initializePusher,
  subscribeToChannels,
  unsubscribeFromChannels,
};
