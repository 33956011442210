//src/components/Bion/CreateProcess/ProcessPlanner/ProcessPlanner.js

import styles from "./ProcessPlanner.module.css";
import DefineBioprocess from "./DefineBioprocess/DefineBioprocess";
import { useEffect, useState } from "react";
import ControlPlanning from "./ControlPlanning/ControlPlanning";

import Media from "./ControlPlanning/Controls/Media/Media";
import Atmospheric from "./ControlPlanning/Controls/Atmospheric/Atmospheric";
import Thermal from "./ControlPlanning/Controls/Thermal/Thermal";
import MechanicalAgitation from "./ControlPlanning/Controls/MechanicalAgitation/MechanicalAgitation";
import MagneticStirring from "./ControlPlanning/Controls/MagneticStirring/MagneticStirring";
import Ph from "./ControlPlanning/Controls/Ph/Ph";
import Exposure from "./ControlPlanning/Controls/Exposure/Exposure";
import Radiation from "./ControlPlanning/Controls/Radiation/Radiation";
import Ultrasound from "./ControlPlanning/Controls/Ultrasound/Ultrasound";
import SampleExtraction from "./ControlPlanning/Controls/SampleExtraction/SampleExtraction";

import AtmosphericControlImg from "../../../../assets/images/PlannerImages/white/AtmosphericControl.png";
import ExposureControlImg from "../../../../assets/images/PlannerImages/white/ExposureControlImg.png";
import MagneticStirringControlImg from "../../../../assets/images/PlannerImages/white/MagneticStirringControl.png";
import MechanicalStirringControlImg from "../../../../assets/images/PlannerImages/white/MechanicalStirring.png";
import MediaIntegrationImg from "../../../../assets/images/PlannerImages/white/MediaIntegration.png";
import PhControlImg from "../../../../assets/images/PlannerImages/white/PhControl.png";
import SampleExtractionImg from "../../../../assets/images/PlannerImages/white/SampleExtraction.png";
import ThermalControlImg from "../../../../assets/images/PlannerImages/white/ThermalControl.png";
import UltrasoundControlImg from "../../../../assets/images/PlannerImages/white/UltrasoundControl.png";
import RadiationControlImg from "../../../../assets/images/PlannerImages/white/RadiationControlImg.png";

import {
  useGetPlannerPromptsQuery,
  useGetPlannerBasePromptQuery,
  useGetPlannerBasePromptOptionsQuery,
} from "../../../../features/administration/administrationApiSlice";
import { Loader2 } from "lucide-react";

const CONTROLS = [
  {
    name: "Media Control",
    reqPromptNames: [
      "MEDIA_LINES",
      "MEDIA_LINE1",
      "MEDIA_INJECT",
      "MEDIA_LINE_CTRL",
    ],
    reqPlaceholdersName: ["media-integrations"],
    component: <Media />,
    img: MediaIntegrationImg,
  },
  {
    name: "Ultrasound Control",
    reqPromptNames: ["USA"],
    reqPlaceholdersName: ["actuators/ultra-sound"],
    component: <Ultrasound />,
    img: UltrasoundControlImg,
  },
  {
    name: "Radiation Control",
    reqPromptNames: ["UVA"],
    reqPlaceholdersName: ["actuators/ultra-violet"],
    component: <Radiation />,
    img: RadiationControlImg,
  },
  {
    name: "Exposure Control",
    reqPromptNames: ["LA"],
    reqPlaceholdersName: ["actuators/light"],
    component: <Exposure />,
    img: ExposureControlImg,
  },
  {
    name: "Ph Control",
    reqPromptNames: ["PHA"],
    reqPlaceholdersName: ["actuators/ph"],
    component: <Ph />,
    img: PhControlImg,
  },
  {
    name: "Mechanical Stirring Control",
    reqPromptNames: ["MESA"],
    reqPlaceholdersName: ["actuators/mechanical-stir"],
    component: <MechanicalAgitation />,
    img: MechanicalStirringControlImg,
  },
  {
    name: "Magnetic Stirring Control",
    reqPromptNames: ["MASA"],
    reqPlaceholdersName: ["actuators/magnetic-stir"],
    component: <MagneticStirring />,
    img: MagneticStirringControlImg,
  },
  {
    name: "Atmospheric Control",
    reqPromptNames: ["GASA"],
    reqPlaceholdersName: ["actuators/gas"],
    component: <Atmospheric />,
    img: AtmosphericControlImg,
  },
  {
    name: "Thermal Control",
    reqPromptNames: ["TEMP"],
    reqPlaceholdersName: ["actuators/temperature"],
    component: <Thermal />,
    img: ThermalControlImg,
  },
  {
    name: "Sample Extration",
    reqPromptNames: ["SAEX"],
    reqPlaceholdersName: ["actuators/sample-extract"],
    component: <SampleExtraction />,
    img: SampleExtractionImg,
  },
];

// Function to generate initial state
const createInitialState = () => {
  const initialState = {};
  CONTROLS.forEach((control) => {
    if (
      control.name === "Media Control" &&
      control.reqPromptNames.includes("MEDIA_LINE_CTRL")
    ) {
      initialState[control.name] = control.reqPromptNames.reduce(
        (acc, promptName) => {
          if (promptName === "MEDIA_LINE_CTRL") {
            acc[promptName] = [[{}]];
          } else {
            acc[promptName] = [{}];
          }
          return acc;
        },
        {}
      );
    } else if (control.reqPromptNames.length > 1) {
      initialState[control.name] = control.reqPromptNames.reduce(
        (acc, promptName) => {
          acc[promptName] = [{}];
          return acc;
        },
        {}
      );
    } else {
      initialState[control.name] = [{}];
    }
  });
  return initialState;
};

export default function ProcessPlanner() {
  const [activeStep, setActiveStep] = useState(1);
  const [plannerData, setPlannerData] = useState(createInitialState);
  const [isLoading, setIsLoading] = useState(true);

  const { data: basePromptData, isSuccess: isSuccessOne } =
    useGetPlannerBasePromptQuery({
      promptType: "BPD",
    });

  const { data: basePromptOptions } = useGetPlannerBasePromptOptionsQuery();

  const {
    data: controls,
    isLoading: isControlsLoading,
    isSuccess: isControlsSuccess,
  } = useGetPlannerPromptsQuery(
    CONTROLS.map(({ component, img, ...obj }) => obj)
  );

  useEffect(() => {
    if (isSuccessOne && isControlsSuccess && basePromptOptions) {
      setIsLoading(false);
    }
  }, [isSuccessOne, isControlsSuccess, basePromptOptions]);

  let updatedControls = [];
  if (isControlsSuccess) {
    updatedControls = controls.map((control) => {
      const comp = CONTROLS.find(
        (ctrl) => ctrl.name === control.name
      ).component;
      const img = CONTROLS.find((ctrl) => ctrl.name === control.name)?.img;
      return { ...control, component: comp, img };
    });
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background">
        <Loader2 className="w-10 h-10 text-blue-500 animate-spin" />
      </div>
    );
  }

  return (
    <div className="page">
      <div className="accordion__container">
        {isControlsSuccess && isSuccessOne && (
          <>
            <DefineBioprocess
              bioprocessDefinitionPrompt={basePromptData.basePrompt}
              options={basePromptOptions}
              isExpanded={activeStep === 0}
            />
            <ControlPlanning
              isExpanded={activeStep === 1}
              plannerData={plannerData}
              setPlannerData={setPlannerData}
              controls={updatedControls}
            />
          </>
        )}
      </div>
    </div>
  );
}
