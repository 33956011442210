import React from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "../../ThemeProvider";

import { Separator } from "../../UI/Shadcn/Separator";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "../../UI/Shadcn/Sidebar";
import { AppSidebar } from "../../MainNavigation/AppSidebar";
import BreadcrumbComponent from "../../Breadcrumb/Breadcrumb";
 
const AppWrapper = () => {


  return (
    <ThemeProvider>
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset>
          <header className="flex mb-[-20px] sm:mb-[0]  h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
            <SidebarTrigger className={"ml-6"} />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbComponent />
          </header>
          <main className="flex-1 sm:ml-22 overflow-auto w-full">
            <Outlet />
          </main>
        </SidebarInset>
      </SidebarProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
