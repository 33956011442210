import {  LogOut, MessageSquare, X } from "lucide-react";

import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,

} from "../../UI/Shadcn/Sidebar";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../../features/auth/authSlice";


const FeedbackModal = ({ isOpen, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState("");
  const maxLength = 2000;

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Feedback submitted:", feedback);
    onSubmit(feedback);
    setFeedback("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-card rounded-lg p-6 w-full max-w-lg shadow-xl "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-primary">
            Tell us what you think!
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value.slice(0, maxLength))}
            placeholder="We'd love to hear your thoughts..."
            className="w-full h-40 p-3 border border-primary/60 rounded-md resize-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all text-foreground bg-background"
          />
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-500">
              {feedback.length}/{maxLength} characters
            </span>
            <button
              type="submit"
              className="px-6 py-2 bg-primary text-white rounded-md hover:bg-primary-80 transition-colors focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
            >
              Submit Feedback
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export function NavUser() {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuItem>
          <SidebarMenuButton onClick={() => setIsFeedbackModalOpen(true)}>
            <MessageSquare className="h-[1.2rem] w-[1.2rem]" />
            <span>Tell us what you think!</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
        <SidebarMenuItem>
          <SidebarMenuButton onClick={handleLogout}>
            <LogOut className="h-[1.2rem] w-[1.2rem]" />
            <span>Log Out</span>
          </SidebarMenuButton>
        </SidebarMenuItem>
        <AnimatePresence>
          {isFeedbackModalOpen && (
            <FeedbackModal
              isOpen={isFeedbackModalOpen}
              onClose={() => setIsFeedbackModalOpen(false)}
              onSubmit={(feedback) => {
                console.log("Feedback submitted:", feedback);
              }}
            />
          )}
        </AnimatePresence>
      </SidebarMenu>
    </SidebarGroup>
   
  );
}
