import useTimer from "../../../hooks/use-timer";
import { useState, useEffect } from "react";

const Timer = ({ shutDownDate, freeze }) => {
  const [freezeTime, setFreezeTime] = useState(null);

  const timeLeftBeforeDisconnecting = Math.max(
    Math.floor((new Date(shutDownDate) - new Date()) / 1000),
    0
  );

  const { secondsLeft } = useTimer(timeLeftBeforeDisconnecting, {
    startAutomatically: true,
    direction: "desc",
  });

  useEffect(() => {
    if (secondsLeft === 0) return;

    if (!freeze) {
      setFreezeTime(null);
    }

    if (!freezeTime) {
      setFreezeTime(secondsLeft);
    }
  }, [freeze, secondsLeft, freezeTime]);

  const formatTime = (seconds) => {
    if (seconds <= 0) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="w-[120px] h-[120px] mt-[30px] mx-auto mb-0 rounded-full relative"
      style={{
        background: `conic-gradient(#2563eb ${
          (secondsLeft / 3600) * 100
        }%, #e2e8f0 0deg)`,
      }}
    >
      <div className="absolute inset-2 bg-background rounded-full"></div>

      <p className="text-[1.5rem] font-bold absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        {formatTime(freeze ? freezeTime : Math.max(secondsLeft, 0))}
      </p>
    </div>
  );
};

export default Timer;
